const projectCard = { maxWidth: '25em', margin: '5px' };
const caraselText = {
    backgroundColor: "rgba(52, 52, 52, 0.4)",
    borderRadius: '5px'
};
const styles = {
    projectCard: projectCard,
    caraselText: caraselText,
};

export default styles;